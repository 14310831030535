<template>
    <div>
        <v-row no-gutters>
            <v-col class="d-flex flex-row align-center">
                <v-btn-toggle v-model="pefcReportsCurrentTab" tile group color="success darken-3 accent-3 ml-0">
                    <v-btn :value="1" class="ml-0">{{ $t('message.annualInAndOut') }}</v-btn>
                    <v-btn :value="2">{{ $t('message.productPortToPort') }}</v-btn>
                    <v-btn :value="3">{{ $t('message.supplierList') }}</v-btn>
                </v-btn-toggle>
            </v-col>
        </v-row>
        <div>
            <PefcAnnualInOutList v-show="pefcReportsCurrentTab == 1"></PefcAnnualInOutList>
            <PefcProductPortToPortList v-show="pefcReportsCurrentTab == 2"></PefcProductPortToPortList>
            <PefcSupplierList v-show="pefcReportsCurrentTab == 3"></PefcSupplierList>
        </div>
    </div>
</template>

<script>
import {mapFields} from "vuex-map-fields";
const PefcAnnualInOutList = () => import('Components/Appic/FscPefc/PefcAnnualInOutList');
const PefcProductPortToPortList = () => import('Components/Appic/FscPefc/PefcProductPortToPortList');
const PefcSupplierList = () => import('Components/Appic/FscPefc/PefcSupplierList');

export default {
    name: "PefcReports",
    components: { PefcAnnualInOutList, PefcProductPortToPortList, PefcSupplierList },
    data() {
        return {
            currentReport: {

            }
        }
    },
    computed: {
        ...mapFields('runtime',{
            pefcReportsCurrentTab: 'pefcReportsCurrentTab'
        })
    },
    methods: {
        exportTable() {

        }
    },
    mounted() {
        if(this.pefcReportsCurrentTab == null) this.pefcReportsCurrentTab = 1
    }
}
</script>

<style scoped>

</style>